import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../components/layouts/DashboardLayout";
import { Typography } from "@mui/material";
import Login from "../pages/Login";

export const IndexPage = lazy(() => import("../pages/Dashboard"));
export const AthletesPage = lazy(() => import("../pages/Athletes"));
export const CouchesPage = lazy(() => import("../pages/Couches"));
export const PlansPage = lazy(() => import("../pages/Plans"));
export const IncomesPage = lazy(() => import("../pages/Incomes"));
export const PendingIncomesPage = lazy(() => import("../pages/PendingIncomes"));
export const PaymentPage = lazy(() => import("../pages/Payment"));
export const ExpensesPage = lazy(() => import("../pages/Expenses"));
export const SnapshotsPage = lazy(() => import("../pages/Snapshots"));
export const Page404 = lazy(() => import("../pages/NotFound"));

// ----------------------------------------------------------------------

const CustomRouter = () => {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense
            fallback={
              <Typography sx={{ margin: "auto" }}>Loading...</Typography>
            }
          >
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: "athletes", element: <AthletesPage /> },
        { path: "couches", element: <CouchesPage /> },
        { path: "plans", element: <PlansPage /> },
        { path: "incomes", element: <IncomesPage /> },
        { path: "pending-incomes", element: <PendingIncomesPage /> },
        { path: "payments", element: <PaymentPage /> },
        { path: "expenses", element: <ExpensesPage /> },
        { path: "snapshots", element: <SnapshotsPage /> },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
};
export default CustomRouter;
