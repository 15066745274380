import { FormEvent, useCallback, useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import { bgGradient } from "../theme/css";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { login } from "../helpers/session";
import { useAppDispatch } from "../hooks";
import { useDispatch } from "react-redux";
import { getProfile, saveToken } from "../features/session/session.slice";
import { addNewToast } from "../features/toast/toast.slice";
import { ErrorMsg, ToastSeverity } from "../enum/toast.enum";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../interfaces/session.interface";

const Login = () => {
  const theme = useTheme();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        const data: Promise<LoginResponse> = (await login({ ...values })).data;
        const token = (await data).access_token;
        if (token) {
          appDispatch(getProfile(token));
          dispatch(saveToken({ access_token: token }));
          nav("/");
        }
      } catch (e: any) {
        const status = e.response?.status;
        let message = ErrorMsg.Default;

        if (status === 404) {
          message = ErrorMsg.UserNotFound;
        }

        if (status === 401) {
          message = ErrorMsg.PasswordInvalid;
        }
        
        dispatch(
          addNewToast({
            severity: ToastSeverity.Error,
            message,
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [values]
  );

  const renderForm = (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3} paddingY={3}>
        <TextField
          name="email"
          label="Email address"
          value={values.email}
          onChange={({ target }) =>
            setValues({ ...values, email: target.value })
          }
        />

        <TextField
          name="password"
          label="Password"
          value={values.password}
          onChange={({ target }) =>
            setValues({ ...values, password: target.value })
          }
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Icon
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        loading={loading}
      >
        Login
      </LoadingButton>
    </form>
  );

  return (
    <>
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.background.default, 0.9),
            imgUrl: "/assets/background/overlay_4.jpg",
          }),
          height: 1,
        }}
      >
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
            }}
          >
            <Typography variant="h4">Sign in to Trackfit</Typography>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Welcome
              </Typography>
            </Divider>

            {renderForm}
          </Card>
        </Stack>
      </Box>
    </>
  );
};

export default Login;
